<template>
  <div class="fullScreen" :class="{ show: showEditDialog }">
    <div v-loading="loading">
      <Crumbs>
        <el-breadcrumb-item slot="after">
          {{ options.id ? (form.list_s == 2 ? '查看' : '编辑') : '新增' }}商务信息
        </el-breadcrumb-item>
      </Crumbs>
      <div class="fullScreenMain">
        <el-tabs v-model="activeName" class="fullScreenMainHeader">
          <el-tab-pane name="0" label="基本信息"></el-tab-pane>
          <el-tab-pane name="1" label="附件"></el-tab-pane>
          <!-- <el-tab-pane name="2" label="商务团队分工" v-if="form.id"></el-tab-pane>
        <el-tab-pane name="3" label="商务追踪" v-if="form.id"></el-tab-pane>
        <el-tab-pane name="4" label="商务绩效" v-if="form.id"></el-tab-pane> -->
        </el-tabs>

        <el-form
          ref="form"
          class="tianbaoForm"
          label-width="0em"
          v-show="activeName == '0'"
          :model="form"
          :rules="rules"
        >
          <div class="fullScreenMainCon" style="width: 100%">
            <div class="big_box_div" style="margin-right: 10px">
              <el-descriptions title="商务基本信息" direction="vertical" :column="3" border>
                <el-descriptions-item :span="3">
                  <template slot="label">
                    <span style="color: red">*</span>
                    商务名称
                  </template>
                  <el-form-item
                    label-width="0px"
                    prop="businessName"
                    ref="businessName"
                    style="width: 100%"
                  >
                    <div @mouseover="mouseoverBusinessName" @mouseout="mouseoutBusinessName">
                      <el-autocomplete
                        :fetch-suggestions="businessQuerySearch"
                        :disabled="form.list_s == 2"
                        v-model="form.businessName"
                        @select="onBusinessNameSelect"
                      >
                      </el-autocomplete>
                    </div>

                    <span v-show="isBusinessNameTooltip" class="businessNameTooltip">{{
                      form.businessName
                    }}</span>
                  </el-form-item>
                </el-descriptions-item>

                <el-descriptions-item label="信息提供人" :span="2">
                  <el-form-item label-width="0px">
                    <SelectDialog @selectDataChange="initiatorFn">
                      <el-input
                        slot="button"
                        :readonly="true"
                        style="cursor: pointer"
                        :disabled="form.list_s == 2"
                        v-model="linshi_initiator"
                        placeholder="请选择信息提供人"
                      ></el-input>
                    </SelectDialog>
                  </el-form-item>
                </el-descriptions-item>
                <el-descriptions-item style="width: 100%">
                  <template slot="label"> 项目承接公司 </template>
                  <Dictionary
                    style="width: 100%"
                    :disabled="form.list_s == 2"
                    v-model="form.companyType"
                    code="COMPANY_TYPE"
                    placeholder="请选择项目承接公司"
                  />
                </el-descriptions-item>
                <el-descriptions-item style="width: 100%">
                  <template slot="label">
                    <span style="color: red">*</span>商务实际开始时间
                  </template>
                  <el-form-item
                    label-width="0px"
                    prop="businessStartDate"
                    ref="businessStartDate"
                    style="width: 100%"
                  >
                    <el-date-picker
                      v-model="form.businessStartDate"
                      :disabled="form.list_s == 2"
                      value-format="timestamp"
                      type="date"
                      :clearable="false"
                      placeholder="选择日期"
                    ></el-date-picker>
                  </el-form-item>
                </el-descriptions-item>
                <el-descriptions-item :span="2">
                  <template slot="label">
                    <span style="color: red">*</span>
                    业主单位
                  </template>
                  <el-form-item
                    style="width: 100%; min-width: 200px"
                    label-width="0px"
                    prop="ownerUnit"
                    ref="ownerUnit"
                  >
                    <el-autocomplete
                      :fetch-suggestions="querySearch"
                      :disabled="form.list_s == 2"
                      v-model="form.ownerUnit"
                      @select="onInputSelect"
                    >
                    </el-autocomplete
                  ></el-form-item>
                </el-descriptions-item>

                <el-descriptions-item>
                  <template slot="label">
                    <span style="color: red">*</span>
                    经办人
                  </template>
                  <el-form-item
                    label-width="0px"
                    prop="contacts"
                    ref="contacts"
                    style="width: 100%"
                  >
                    <el-input :disabled="form.list_s == 2" v-model="form.contacts"> </el-input>
                  </el-form-item>
                </el-descriptions-item>
                <el-descriptions-item label="经办人职务">
                  <el-input :disabled="form.list_s == 2" v-model="form.post"> </el-input
                ></el-descriptions-item>
                <el-descriptions-item>
                  <template slot="label">
                    <span style="color: red">*</span>
                    经办人电话
                  </template>
                  <el-form-item
                    label-width="0px"
                    prop="contactsPhone"
                    ref="contactsPhone"
                    style="width: 100%"
                  >
                    <el-input :disabled="form.list_s == 2" v-model="form.contactsPhone"> </el-input>
                  </el-form-item>
                </el-descriptions-item>

                <el-descriptions-item label="决策人">
                  <el-input :disabled="form.list_s == 2" v-model="form.decisionMaker"> </el-input
                ></el-descriptions-item>
                <el-descriptions-item label="决策人职务">
                  <el-input :disabled="form.list_s == 2" v-model="form.decisionMakerPost">
                  </el-input
                ></el-descriptions-item>
                <el-descriptions-item label="决策人电话">
                  <el-form-item
                    label-width="0px"
                    prop="decisionMakerContactsPhone"
                    ref="decisionMakerContactsPhone"
                    style="width: 100%"
                  >
                    <el-input
                      :disabled="form.list_s == 2"
                      v-model="form.decisionMakerContactsPhone"
                    >
                    </el-input>
                  </el-form-item>
                </el-descriptions-item>

                <el-descriptions-item>
                  <template slot="label"> 招标方式 </template>
                  <el-form-item prop="bidWay" style="width: 100%">
                    <!-- <Dictionary
              style="width: 100%"
              :disabled="disabled"
              v-model="form.bidWay"
              code="BUSINESS_TOUBIAO"
              placeholder="请选择招标方式"
            /> -->
                    <el-select
                      v-model="form.bidWay"
                      placeholder="请选择"
                      :disabled="form.list_s == 2"
                    >
                      <el-option
                        v-for="item in dictData.bidwayOptions"
                        :key="item.dictVal"
                        :label="item.dictName"
                        :value="item.dictVal"
                      >
                      </el-option> </el-select
                  ></el-form-item>
                </el-descriptions-item>
                <el-descriptions-item label="预计合同金额(元)">
                  <el-form-item label-width="0px" prop="" ref="predictMoney">
                    <el-input
                      :disabled="form.list_s == 2"
                      v-model.trim="form.predictMoney"
                      @input="is_number('predictMoney')"
                      onkeyup="(function(){if(isNaN(delcommafy(value)))execCommand('undo') })"
                      onafterpaste="(function(){if(isNaN(delcommafy(value)))execCommand('undo') })"
                      placeholder="请输入"
                    ></el-input>
                  </el-form-item>
                </el-descriptions-item>
                <el-descriptions-item label="我方合同金额(元)">
                  <el-form-item label-width="0px" prop="" ref="ourContractAmount">
                    <el-input
                      :disabled="form.list_s == 2"
                      v-model.trim="form.ourContractAmount"
                      @input="is_number('ourContractAmount')"
                      @blur="inputMoney($event, 'ourContractAmount')"
                      onkeyup="(function(){if(isNaN(delcommafy(value)))execCommand('undo') })"
                      onafterpaste="(function(){if(isNaN(delcommafy(value)))execCommand('undo') })"
                      placeholder="请输入"
                    ></el-input>
                    <div style="color: red" v-show="isourContractAmount">当前金额小于5000</div>
                  </el-form-item>
                </el-descriptions-item>
                <!-- <el-descriptions-item label="金额大写"
                  >{{ predictMoneyChinese }}
                </el-descriptions-item> -->
              </el-descriptions>
              <el-descriptions style="margin-top: 5px" direction="vertical" :column="1" border>
                <el-descriptions-item label="商务说明">
                  <template slot="label">
                    <span style="color: red">*</span>
                    商务说明
                  </template>
                  <el-form-item label-width="0px" prop="content" ref="content" style="width: 100%">
                    <el-input
                      type="textarea"
                      v-model="form.content"
                      placeholder="XX时间在XX地方参与了XX组织的XX活动，说明潜在的公司利益相关点。"
                      :disabled="form.list_s == 2"
                    ></el-input> </el-form-item
                ></el-descriptions-item>
              </el-descriptions>

              <el-descriptions
                style="margin-top: 10px"
                title="税率"
                direction="vertical"
                :column="5"
                border
              >
                <el-descriptions-item label="我方合同金额(元)" :span="1">
                  <el-input
                    :disabled="form.list_s == 2"
                    @input="inputFn('ourContractAmount')"
                    @focus="focusFn('ourContractAmount')"
                    @blur="blurFn('ourContractAmount')"
                    v-model="form.ourContractAmount"
                  >
                  </el-input>
                  <div style="color: red" v-show="isourContractAmount">
                    当前金额小于5000
                  </div></el-descriptions-item
                >
                <el-descriptions-item label="税费类型" :span="1">
                  <template slot="label">
                    税费类型
                    <el-button
                      size="mini"
                      type="warning"
                      style="margin-left: 10%; padding: 5px"
                      @click="showTaxInfo"
                      >税率类型说明</el-button
                    >
                  </template>
                  <el-form-item prop="taxType" style="width: 100%">
                    <Dictionary
                      :disabled="form.list_s == 2"
                      v-model="form.taxType"
                      code="TAX_TYPE"
                      placeholder="请选择税费类型"
                      :filterable="true"
                      @change="onChangeTaxtype"
                  /></el-form-item>
                </el-descriptions-item>
                <el-descriptions-item label="增值税类型" :span="2">
                  <template slot="label">
                    增值税类型
                    <el-button
                      size="mini"
                      type="warning"
                      style="margin-left: 10%; padding: 5px"
                      @click="showVatTypesShow"
                      >增值税类型说明</el-button
                    >
                  </template>
                  <el-form-item prop="vatType" style="width: 100%">
                    <el-select
                      v-model="form.vatType"
                      :disabled="form.list_s == 2 || form.taxType == 'THIRD_PARTY_TAX'"
                      @change="dataUpdate"
                      placeholder="请选择增值税类型"
                      class="ipt_width"
                      style="width: 100%"
                    >
                      <el-option
                        v-for="item in zzsList"
                        :key="item.id"
                        :label="item.vatName"
                        :value="item.vatType"
                      >
                      </el-option> </el-select
                  ></el-form-item>
                </el-descriptions-item>
                <el-descriptions-item label="增值税类型详细" :span="1">
                  <template slot="label"> 增值税类型详细 </template>
                  <el-form-item prop="vatData" style="width: 100%">
                    <VatDictionary
                      style="width: 100%"
                      valType="Data"
                      :disabled="form.list_s == 2 || form.taxType == 'THIRD_PARTY_TAX'"
                      v-model="form.vatData"
                      :clearable="false"
                      :code="form.vatType"
                      placeholder="请选择增值税类型详细"
                    />
                  </el-form-item>
                </el-descriptions-item>
                <el-descriptions-item>
                  <template slot="label"> 增值税税率(%) </template>
                  <el-form-item style="width: 100%">
                    <VatDictionary
                      style="width: 100%"
                      valType="TaxRate"
                      :disabled="form.list_s == 2 || form.taxType == 'THIRD_PARTY_TAX'"
                      v-model="form.vatRate"
                      :code="form.vatData"
                      :clearable="false"
                      @optionsChange="optionsChange"
                      placeholder="请选择增值税税率"
                  /></el-form-item>
                </el-descriptions-item>
                <el-descriptions-item label="增值税">
                  <span v-if="j_configcomp">{{ j_configcomp }} （元）</span>
                  <span v-else>0 （元）</span>
                </el-descriptions-item>
                <el-descriptions-item label="附加税税率">12%</el-descriptions-item>
                <el-descriptions-item label="附加税">
                  <span v-if="j_actualAdditionalTax">{{ j_actualAdditionalTax }} （元）</span>
                  <span v-else>0 （元）</span>
                </el-descriptions-item>
                <el-descriptions-item label="印花税税率">0.03%</el-descriptions-item>
                <el-descriptions-item label="印花税">
                  <span v-if="j_actualStampTax">{{ j_actualStampTax }} （元）</span>
                  <span v-else>0 （元）</span>
                </el-descriptions-item>
                <el-descriptions-item label="税费">
                  <span v-if="taxesFee">{{ taxesFee }} （元）</span>
                  <span v-else>0 （元）</span>
                </el-descriptions-item>
              </el-descriptions>
            </div>

            <div class="big_box_div" style="margin-left: 10px">
              <!-- <el-descriptions title="业务类型" direction="vertical" :column="1" border>
                <el-descriptions-item>
                  <template slot="label"> 商务类型 </template>
                  <Dictionary
                    :disabled="form.list_s == 2"
                    v-model="form.businessType"
                    code="BUSINESS_TYPE"
                    placeholder="请选择商务类型"
                  />
                </el-descriptions-item>
              </el-descriptions> -->
              <el-descriptions title="业务类型" direction="vertical" :column="3" border>
                <el-descriptions-item>
                  <template slot="label"> 商务类型 </template>
                  <el-form-item label-width="0px" style="width: 100%" prop="businessType">
                    <Dictionary
                      :disabled="form.list_s == 2"
                      v-model="form.businessType"
                      code="BUSINESS_TYPE"
                      placeholder="请选择商务类型"
                    />
                  </el-form-item>
                </el-descriptions-item>
                <el-descriptions-item>
                  <template slot="label">
                    业务种类
                    <el-button
                      size="mini"
                      type="warning"
                      style="margin-left: 10%; padding: 5px"
                      @click="showBusinessLinesIsShow"
                      >业务种类说明</el-button
                    >
                  </template>
                  <el-form-item prop="businessLines" style="width: 100%">
                    <Dictionary
                      :disabled="form.list_s == 2"
                      v-model="form.businessLines"
                      code="BUSINESS_LINES"
                      placeholder="请选择业务种类"
                      :filterable="true"
                  /></el-form-item>
                </el-descriptions-item>
                <el-descriptions-item label="项目种类">
                  <Dictionary
                    :disabled="form.list_s == 2"
                    v-model="form.projectLines"
                    :code="form.businessLines"
                    :filterable="true"
                    placeholder="请选择项目种类"
                  />
                </el-descriptions-item>
                <el-descriptions-item :span="3">
                  <template slot="label">
                    项目来源
                    <el-button
                      size="mini"
                      type="warning"
                      style="margin-left: 10%; padding: 5px"
                      @click="projectSourceIsShow = true"
                      >项目来源说明</el-button
                    >
                  </template>
                  <el-form-item prop="projectSource" style="width: 100%">
                    <el-radio-group v-model="form.projectSource">
                      <div class="radio-group">
                        <div class="radio-label">生产类型:</div>
                        <div class="radio-item">
                          <el-radio
                            :disabled="form.list_s == 2"
                            v-for="item in dictData.produceList"
                            :key="item.id"
                            :label="item.dictVal"
                            >{{ item.dictName }}</el-radio
                          >
                        </div>
                      </div>
                      <div class="radio-group">
                        <div class="radio-label">非生产类型:</div>
                        <div class="radio-item">
                          <el-radio
                            :disabled="form.list_s == 2"
                            v-for="item in dictData.unProduceList"
                            :key="item.id"
                            :label="item.dictVal"
                            >{{ item.dictName }}</el-radio
                          >
                        </div>
                      </div>
                    </el-radio-group></el-form-item
                  >
                </el-descriptions-item>
                <el-descriptions-item
                  label="合作费比例(%)"
                  v-if="form.projectSource == 'zi_zhi_he_zuo'"
                >
                  <el-input-number
                    :precision="2"
                    :step="0.1"
                    :max="100"
                    :disabled="form.list_s == 2"
                    v-model="form.collaborationCostsRatio"
                  ></el-input-number>
                </el-descriptions-item>
                <el-descriptions-item
                  label="外部合作者"
                  v-if="permission(['EDIT_EXTERNAL'])"
                  :span="2"
                >
                  <el-form-item prop="externalPartnere" style="width: 100%">
                    <el-radio
                      :disabled="form.list_s == 2"
                      @input="externalPartnereFn"
                      v-model="form.externalPartnere"
                      label="NOT"
                      >无外部合作者</el-radio
                    >
                    <el-radio
                      :disabled="form.list_s == 2"
                      @input="externalPartnereFn"
                      v-model="form.externalPartnere"
                      label="HAVE"
                      >有外部合作者</el-radio
                    ></el-form-item
                  >
                </el-descriptions-item>
              </el-descriptions>
              <el-descriptions
                v-if="form.externalPartnere == 'HAVE'"
                style="margin-top: 10px"
                title="外部协作信息"
                direction="vertical"
                :column="2"
                border
              >
                <el-descriptions-item label="费用结算方式">
                  <el-form-item prop="contentCooperationType" style="width: 100%">
                    <el-select
                      style="width: 100%"
                      :disabled="form.list_s == 2"
                      v-model="form.contentCooperationType"
                      placeholder="请选择"
                    >
                      <el-option label="按具体金额" :value="1"> </el-option>
                      <el-option label="按比例" :value="2"> </el-option> </el-select
                  ></el-form-item>
                </el-descriptions-item>
                <el-descriptions-item
                  label="预计外部协作资金金额(元)"
                  v-if="form.contentCooperationType == 1"
                >
                  <el-form-item prop="contentCooperationMoney" style="width: 100%">
                    <el-input
                      v-model="form.contentCooperationMoney"
                      placeholder="请输入"
                      @input="inputFn('contentCooperationMoney')"
                      @focus="focusFn('contentCooperationMoney')"
                      @blur="blurFn('contentCooperationMoney')"
                      :disabled="form.list_s == 2"
                    ></el-input
                  ></el-form-item>
                </el-descriptions-item>
                <el-descriptions-item
                  label="预计外部协作资金比例(%)"
                  v-if="form.contentCooperationType == 2"
                >
                  <el-form-item prop="contentCooperationRatio" style="width: 100%">
                    <el-input
                      v-model="form.contentCooperationRatio"
                      placeholder="请输入"
                      :disabled="form.list_s == 2"
                      oninput="value=value.replace(/^\D*(\d*(?:\.\d{0,2})?).*$/g, '$1');if(value>100)value=100;if(value<0)value=null"
                    >
                    </el-input
                  ></el-form-item>
                </el-descriptions-item>
                <el-descriptions-item label="外部协作内容" :span="2">
                  <el-form-item prop="contentCooperationContent" style="width: 100%">
                    <el-input
                      type="textarea"
                      autosize
                      v-model="form.contentCooperationContent"
                      placeholder="请输入"
                      :disabled="form.list_s == 2"
                    ></el-input
                  ></el-form-item>
                </el-descriptions-item>
              </el-descriptions>

              <el-descriptions
                style="margin-top: 5px"
                title="角色分配"
                direction="vertical"
                :column="3"
                border
              >
                <el-descriptions-item label="建议商务负责人">
                  <el-form-item label-width="0px">
                    <SelectDialog @selectDataChange="suggestionPrincipalUserNameFn">
                      <el-input
                        slot="button"
                        :readonly="true"
                        :disabled="form.list_s == 2"
                        style="cursor: pointer"
                        v-model="form.suggestionPrincipalUserName"
                        placeholder="请选择建议商务负责人"
                      ></el-input>
                    </SelectDialog>
                  </el-form-item>
                </el-descriptions-item>
                <template v-if="userInfo && userInfo.weight >= 10000">
                  <!-- 是否名义负责人 -->
                  <el-descriptions-item label="是否名义负责人">
                    <el-form-item label-width="0px">
                      <el-radio-group
                        :disabled="form.list_s == 2"
                        v-model="form.isNominalPrincipalUser"
                        @change="changeIsNominalPrincipalUser"
                      >
                        <el-radio :label="1">是</el-radio>
                        <el-radio :label="0">否</el-radio>
                      </el-radio-group>
                    </el-form-item>
                  </el-descriptions-item>
                  <el-descriptions-item>
                    <template slot="label">
                      <span style="color: red">*</span>
                      {{ form.isNominalPrincipalUser ? '名义商务负责人' : '实际商务负责人' }}
                    </template>
                    <el-form-item
                      label-width="0px"
                      prop="principalUserName"
                      ref="principalUserName"
                    >
                      <SelectDialog @selectDataChange="principalUserFn">
                        <el-input
                          slot="button"
                          :readonly="true"
                          style="cursor: pointer"
                          :disabled="form.list_s == 2"
                          v-model="form.principalUserName"
                          :placeholder="
                            form.isNominalPrincipalUser
                              ? '请选择名义商务负责人'
                              : '请选择实际商务负责人'
                          "
                        ></el-input>
                      </SelectDialog>
                    </el-form-item> </el-descriptions-item
                ></template>
              </el-descriptions>
            </div>

            <div v-show="opinionRecordList.length > 0">
              <div class="text-center" style="margin-top: 20px">
                <h3 v-if="form.id">意见区:</h3>
                <template>
                  <div class="text-center-top" v-for="i in opinionRecordList" :key="i.id">
                    <span class="text-center-top-left">
                      <el-button
                        class="text-btn"
                        type="success"
                        disabled
                        v-if="i.isCheckPass === '1'"
                        >通过</el-button
                      >
                      <el-button type="info" class="text-btn" disabled v-else>不通过</el-button>
                      <span class="opinion">审批意见: {{ i.opinion }}</span>
                    </span>
                    <span class="text-center-top-right">
                      <span class="name">处理人: {{ i.auditUserName }}</span>
                      <span class="day">审批时间: {{ i.auditDate | timeFormat }}</span>
                    </span>
                  </div>
                </template>
              </div>
            </div>
          </div>
        </el-form>

        <Annex
          :manageId="form.id"
          @changed="fileListFn"
          :file_Data="file_Data"
          v-show="activeName == 1"
        ></Annex>
        <!-- <Assign
        width="600px"
        :ManageId="form.id"
        v-show="activeName == 2"
      ></Assign>
      <Business
        width="600px"
        :BusinessId="form.id"
        v-show="activeName == 3"
      ></Business>
      <DistriBution
        width="600px"
          :options="{businessManageId:form.id}"
          :Disabled="true"
        v-if="activeName == 4"
      ></DistriBution> -->

        <div class="fullScreenOperation">
          <el-button
            v-if="form.list_s != 2 && (form.status == '0' || form.status == '21')"
            type="primary"
            :loading="loading"
            @click="handleSave(1)"
          >
            提交
          </el-button>
          <el-button
            v-if="form.list_s != 2 && (form.status == '0' || form.status == '21')"
            type="success"
            :loading="loading"
            @click="handleSave(0)"
          >
            暂存
          </el-button>
          <el-button
            v-if="
              (form.list_s == 2 && form.status == 0) ||
              (form.list_s == 2 && form.status == 1) ||
              (form.list_s == 2 && form.status == 21) ||
              form.list_s == 3
            "
            type="danger"
            :loading="loading"
            @click="delFn"
          >
            删除商务信息
          </el-button>
          <el-button type="info" @click="re">返回</el-button>
        </div>
      </div>
    </div>

    <el-dialog width="50%" title="项目来源说明" append-to-body :visible.sync="projectSourceIsShow">
      <div class="imgDialog">
        <img :src="`${imgProjectSource}?${new Date().getTime()}`" alt="项目来源说明" width="100%" />
      </div>
    </el-dialog>

    <el-dialog
      width="50%"
      title="业务种类说明"
      append-to-body
      :visible.sync="businessLinesIsShow"
      class="businessLinesDialog"
    >
      <el-table
        :data="businessLinesTableData"
        style="width: 100%"
        border
        height="550px"
        :cell-class-name="clearColumnPadding"
        size="small"
      >
        <el-table-column
          prop="dictName"
          label="业务种类"
          align="center"
          width="350px"
          class-name="height-light"
        >
        </el-table-column>
        <el-table-column label="项目种类" :show-overflow-tooltip="false">
          <template slot-scope="scope">
            <el-table :data="scope.row.dictDataTwoList" style="width: 100%" :show-header="false">
              <el-table-column prop="dictName" align="center" class-name="content-text">
              </el-table-column>
            </el-table>
          </template>
        </el-table-column>
      </el-table>
    </el-dialog>

    <el-dialog
      width="60%"
      title="增值税类型说明"
      append-to-body
      :visible.sync="vatTypesShow"
      class="vatDialog"
    >
      <el-table
        :data="VatTypesTableData"
        style="width: 100%"
        row-key="id"
        border
        default-expand-all
        height="550px"
        :tree-props="{ children: 'dictDataTwoList' }"
        :cell-class-name="vatTypeTableCellStyle"
        :span-method="arraySpanMethod"
      >
        <el-table-column prop="vatName" label="内容"> </el-table-column>
        <el-table-column label="税率" :show-overflow-tooltip="false" width="100px">
          <template slot-scope="scope">
            <el-table
              v-if="scope.row.dictDataThreeList && scope.row.dictDataThreeList.length !== 0"
              :data="scope.row.dictDataThreeList"
              style="width: 100%; height: 100%"
              :show-header="false"
              cell-class-name="cellContent"
              row-class-name="clear-right-border"
            >
              <el-table-column prop="vatRate" width="100px" :show-overflow-tooltip="false">
                <template slot-scope="scope">
                  {{ scope.row.vatRate + '%' }}
                </template>
              </el-table-column>
              <el-table-column prop="remark" :show-overflow-tooltip="false">
                <template slot-scope="scope">
                  <div class="remark-box">
                    {{ scope.row.remark }}
                  </div>
                </template>
              </el-table-column>
            </el-table>
            <div v-else></div>
          </template>
        </el-table-column>
        <el-table-column prop="remark" label="备注"> </el-table-column>
      </el-table>
    </el-dialog>

    <el-dialog
      width="40%"
      title="商务税费类型说明"
      append-to-body
      :visible.sync="taxTypesShow"
      class="vatDialog"
    >
      <el-table
        :data="taxTypesTableData"
        style="width: 100%"
        border
        height="300px"
        :cell-class-name="vatTypeTableCellStyle"
        :span-method="texTableCellMerge"
      >
        <el-table-column prop="taxType" label="税率类型"> </el-table-column>
        <el-table-column prop="projectNature" label="项目性质"></el-table-column>
        <el-table-column prop="projectSource" label="项目来源"> </el-table-column>
      </el-table>
    </el-dialog>
  </div>
</template>
<script>
import { mapState } from 'vuex'
import { phone2 } from '@/util/regular'
import {
  getInputValue,
  delcommafy,
  numberToCurrencyNo,
  changeMoneyToChinese,
} from '@/util/jsencrypt'

export default {
  components: {
    Crumbs: () => import('@/components/Crumbs.vue'),
    Annex: () => import('./Annex.vue'),
    Dictionary: () => import('@/components/Dictionary.vue'),
    Assign: () => import('@/views/publicView/Assign.vue'),
    Business: () => import('@/views/publicView/Business.vue'),
    DistriBution: () => import('@/views/publicView/DistriBution.vue'),
    EditDialog: () => import('@/components/EditDialog.vue'),
    SelectDialog: () => import('@/components/selectDialog.vue'),
    VatDictionary: () => import('@/components/VatDictionary.vue'),
  },

  props: {
    isShow: {
      type: Boolean,
      default: false,
    },
    options: {
      type: Object,
      default: function () {
        return {}
      },
    },
  },
  computed: {
    ...mapState({
      userInfo: state => state.user.userInfo,
    }),
    taxesFee() {
      //税费
      let num = 0
      let j_configcomp = this.j_configcomp ? this.j_configcomp : 0
      let j_actualStampTax = this.j_actualStampTax ? this.j_actualStampTax : 0
      let j_actualAdditionalTax = this.j_actualAdditionalTax ? this.j_actualAdditionalTax : 0
      num = Number(j_configcomp) + Number(j_actualStampTax) + Number(j_actualAdditionalTax)
      this.form.taxesFee = num.toFixed(2)
      return num.toFixed(2)
    },
    j_actualAdditionalTax() {
      //附加税
      let num = 0
      let j_configcomp = this.j_configcomp ? this.j_configcomp : 0
      num = (j_configcomp * 0.12).toFixed(2)
      this.form.additionalTaxRate = 12 //附加税率
      this.form.actualAdditionalTax = num
      return num
    },
    j_actualStampTax() {
      //印花税
      let ourContractAmount = Number(delcommafy(this.form.ourContractAmount))
      let num = 0
      if (this.form.taxType === 'SPLIT_TAX') {
        ourContractAmount -= this.calculateCooperationMoney(ourContractAmount)
        num = (ourContractAmount * 0.0003).toFixed(2)
      } else if (this.form.taxType === 'SELF_PAY_TAX') {
        // 直接计算
        num = (ourContractAmount * 0.0003).toFixed(2)
      } else if (this.form.taxType === 'THIRD_PARTY_TAX') {
        num = 0
      }
      this.form.stampTaxRate = 0.03 //印花税率
      this.form.actualStampTax = num
      return num
    },
    j_configcomp() {
      //增值税
      let ourContractAmount = Number(delcommafy(this.form.ourContractAmount))
      let num = 0
      if (this.form.taxType === 'SPLIT_TAX') {
        if (this.form.vatType) {
          ourContractAmount -= this.calculateCooperationMoney(ourContractAmount)
        }
        num = this.calculateVat(ourContractAmount)
      } else if (this.form.taxType === 'SELF_PAY_TAX' && this.form.vatType) {
        // 直接计算
        num = this.calculateVat(ourContractAmount)
      } else if (this.form.taxType === 'THIRD_PARTY_TAX') {
        num = 0
      }
      this.form.actualVat = num
      return num
    },
    tenderFeeChinese() {
      if (this.form.tenderFee) {
        const numMoney = delcommafy(this.form.tenderFee)
        return changeMoneyToChinese(numMoney)
      } else {
        return '零圆整'
      }
    },
    // 我方合同金额转中文大写
    ourContractAmountChinese() {
      if (this.form.ourContractAmount) {
        const numMoney = delcommafy(this.form.ourContractAmount)
        return changeMoneyToChinese(numMoney)
      } else {
        return '零圆整'
      }
    },
  },
  data() {
    // 商务名称自定义校验规则
    var validateBusiness = (rule, value, callback) => {
      if (this.form.list_s !== 2) {
        if (value == null || value.trim() == '') {
          callback(new Error('请输入商务名称'))
        } else {
          if (value === '前期面上沟通') {
            callback(new Error('商务名称不能为前期面上沟通，请重新输入'))
          } else {
            let flag = this.businessNameList.some(item => item.value == value)
            if (flag && value !== this.selectBusinessName) {
              // 说明有重复的商务名称，回显校验提示内容
              callback(new Error('当前商务名称与历史商务重复，请注意修改'))
            } else {
              callback()
            }
          }
        }
      } else {
        callback()
      }
    }
    return {
      taxTypesShow: false,
      showEditDialog: false,
      loading: false,
      submitLoading: false,
      form: { ourContractAmount: 0, vatRate: 0 },
      activeName: '0',
      rules: {
        businessName: [
          // {
          //   required: true,
          //   message: '请输入商务名称',
          //   trigger: 'blur',
          // },
          { validator: validateBusiness, trigger: 'change' },
        ],
        ownerUnit: [
          {
            required: true,
            message: '请输入业主单位',
            trigger: 'blur',
          },
          // {
          //   pattern: phone2,
          //   message: "请输入正确的联系电话",
          //   trigger: ["change", "blur"],
          // },
        ],
        contacts: [
          {
            required: true,
            message: '请输入经办人',
            trigger: 'blur',
          },
        ],
        contactsPhone: [
          {
            required: true,
            message: '请输入经办人电话',
            trigger: 'blur',
          },
          {
            pattern: phone2,
            message: '请输入正确的联系电话',
            trigger: ['change', 'blur'],
          },
        ],
        decisionMakerContactsPhone: [
          {
            pattern: phone2,
            message: '请输入正确的联系电话',
            trigger: ['change', 'blur'],
          },
        ],
        content: [
          {
            required: true,
            message: '请输入商务活动相关内容',
            trigger: 'blur',
          },
        ],
        // businessType: [
        //   {
        //     required: true,
        //     message: "请选择商务类型",
        //     trigger: ["blur", "change"],
        //   }
        // ]
        businessStartDate: [
          {
            required: true,
            message: '选择商务实际开始时间',
            trigger: ['blur', 'change'],
          },
        ],
        principalUserName: [
          {
            required: true,

            trigger: ['blur', 'change'],
            validator: (rule, value, callback) => {
              if (this.userInfo && this.userInfo.weight >= 10000 && !value) {
                callback(new Error('请选择商务负责人'))
              } else {
                callback()
              }
            },
          },
        ],
      },
      deptList: [], // 部门列表
      postList: [], //岗位列表
      type: 'portrait',
      file_Data: [],
      y_file_Data: [],
      list_s: '0', //列表进入状态
      val: {},
      opinionRecordList: [],
      user_show: false,
      UserData: [],
      linshi_initiator: null,
      user_Data: [],
      suggestion_show: false,

      companyList: [], //业主单位数组
      businessNameList: [], //商务名称数组
      selectBusinessName: null,
      businessFileList: [],
      dictData: {
        bidwayOptions: [],
        projectSource: [],
        produceList: [],
        unProduceList: [],
      },
      imgUrl: 'https://pro.huitukj.com/iip-api/profile/OPERATION_MANUAL/业务种类.png',
      imgProjectSource: 'https://pro.huitukj.com/iip-api/profile/OPERATION_MANUAL/生产费用.png',
      opinionRecordList: [],
      temporaryVatRate: null,
      vatTypesShow: false,
      imgVatTypes: 'https://pro.huitukj.com/iip-api/profile/OPERATION_MANUAL/税率表.png',
      businessLinesIsShow: false,
      projectSourceIsShow: false,
      businessLinesTableData: [], //业务种类弹框数据
      VatTypesTableData: [], //增值税弹框数据
      zzsList: [],
      isBusinessNameTooltip: false,
      isourContractAmount: false, //合同提示
      colFields: ['taxType', 'projectNature', 'projectSource'], // 存放所有的表头 一定要与taxTypesTableData一致
      // 存储税率类型说明
      taxTypesTableData: [
        {
          taxType: '我方支付所有税费',
          projectNature: '生产项目',
          projectSource: '一手直签',
        },
        {
          taxType: '我方支付所有税费',
          projectNature: '生产项目',
          projectSource: '资质合作',
        },
        {
          taxType: '我方支付所有税费',
          projectNature: '生产项目',
          projectSource: '分包转包',
        },
        {
          taxType: '各付各税',
          projectNature: '合作项目',
          projectSource: '资质提供',
        },
        {
          taxType: '各付各税',
          projectNature: '合作项目',
          projectSource: '整体外协',
        },
        {
          taxType: '各付各税',
          projectNature: '合作项目',
          projectSource: '商务居间',
        },
        {
          taxType: '无税',
          projectNature: '零星项目',
          projectSource: '不签合同不开票不开收据的零星项目',
        },
      ], // 税率类型弹框数据
      spanArr: [], // 存储计算合并的行数
    }
  },
  inject: ['re'],
  created() {
    this.listConfig()
    this.getPartnerCompany()
    this.getBusinessNameList()
    this.$api.dict
      .listSysDictData('BUSINESS_TOUBIAO', true)
      .then(res => {
        this.dictData.bidwayOptions = res.data
      })
      .catch(err => {
        console.log(err)
      })
    this.$api.dict
      .listSysDictData('PROJECT_SOURCE', true)
      .then(res => {
        this.dictData.projectSource = res.data
        const produceList = []
        const unProduceList = []
        this.dictData.projectSource.forEach(item => {
          switch (item.dictVal) {
            case 'yi_shou_zhi_qian':
              produceList.push(item)
              break
            case 'zi_zhi_he_zuo':
              produceList.push(item)
              break
            case 'fen_bao_zhuan_bao':
              produceList.push(item)
              break
            case 'zi_zhi_ti_gong':
              unProduceList.push(item)
              break
            case 'zheng_ti_wai_xie':
              unProduceList.push(item)
              break
            case 'shang_wu_ju_jian':
              unProduceList.push(item)
              break
          }
        })
        this.dictData.produceList = produceList
        this.dictData.unProduceList = unProduceList
      })
      .catch(err => {
        console.log(err)
      })
  },
  watch: {
    isShow: {
      immediate: true, //初始化立即执行
      handler: function (newVal) {
        this.showEditDialog = newVal
        if (newVal) {
          this.activeName = '0'
          // this.opinionRecordList = []
          // this.getUser()
        }
      },
    },
    options: {
      immediate: true, //初始化立即执行
      deep: true, //对象深度监测
      handler: function (newVal, oldVal) {
        this.val = newVal
        // this.form.id = newVal.id;
        this.list_s = this.val.list_s
        if (newVal.id) {
          // this.getData()
          this.init()
        } else {
          this.form = {
            ...newVal,
            initiatorId: this.userInfo.staffId,
            provideId: this.userInfo.staffId,
            ourContractAmount: null,
            predictMoney: null,
            taxType: null,
            vatRate: null,
            vatData: null,
            vatRate: null,
            tenderFee: null,
            contentCooperationMoney: null,
            companyType: null,
            ownerUnit: null,
            contacts: null,
            post: null,
            contactsPhone: null,
            decisionMaker: null,
            decisionMakerPost: null,
            decisionMakerContactsPhone: null,
            content: null,
            businessType: null,
            businessLines: null,
            projectLines: null,
            projectSource: null,
            collaborationCostsRatio: null,
            externalPartnere: null,
            contentCooperationType: null,
            contentCooperationMoney: null,
            contentCooperationRatio: null,
            contentCooperationContent: null,
            suggestionPrincipalUserName: null,
            businessStartDate: new Date().getTime(),
            isNominalPrincipalUser: 0,
            principalUserName: null,
          }
          // this.linshi_initiator = this.userInfo.userName;
          var newArr = this.userInfo.deptNames.split(',')
          this.linshi_initiator = this.userInfo.userName + ' - ' + newArr[0]
          this.file_Data = []
          if (this.$refs.form) {
            this.$refs.form.clearValidate() //清除表单验证提示
          }

          this.$api.dict
            .listSysDictData('BUSINESS_LINES', true)
            .then(res => {
              if (res.data) {
                this.form.businessLines = res.data[0].dictVal
              }
            })
            .catch(err => {
              console.log(err)
            })
        }
      },
    },
    'form.contentCooperationType': {
      handler: function (val) {
        if (val == 1) {
          this.form.contentCooperationRatio = null
        } else if (val == 2) {
          this.form.contentCooperationMoney = null
        } else {
          this.form.contentCooperationMoney = null
          this.form.contentCooperationRatio = null
        }
      },
    },
  },
  methods: {
    principalUserFn(row) {
      var arr = row.deptNames.split(',')
      this.form.principalUserName = row.userName + ' - ' + arr[0]
      this.form.principalUserId = row?.id
      this.$forceUpdate()
    },
    changeIsNominalPrincipalUser() {
      if (this.form?.isNominalPrincipalUser) {
        this.rules.principalUserName = [
          {
            required: true,
            message: '请选择名义商务负责人',
            trigger: ['blur', 'change'],
          },
        ]
      } else {
        this.rules.principalUserName = [
          {
            required: true,
            message: '请选择实际商务负责人',
            trigger: ['blur', 'change'],
          },
        ]
      }
    },
    showTaxInfo() {
      this.getSpanArr(this.taxTypesTableData)
      this.taxTypesShow = true
    },
    /**
     * 分析每一列，找出相同的
     * @param data
     */
    getSpanArr(data) {
      for (let i = 0; i < this.taxTypesTableData.length; i++) {
        let row = i
        // let col = i % this.colCount;
        if (row === 0) {
          // i 表示行 j表示列
          for (let j = 0; j < this.colFields.length; j++) {
            this.spanArr[i * this.colFields.length + j] = {
              rowspan: 1,
              colspan: 1,
            }
          }
        } else {
          for (let j = 0; j < this.colFields.length; j++) {
            // 当前和上一次的一样
            // 1. 合并所有列的相同数据单元格
            if (
              this.taxTypesTableData[row][this.colFields[j]] ===
              this.taxTypesTableData[row - 1][this.colFields[j]]
            ) {
              let beforeItem = this.spanArr[(row - 1) * this.colFields.length + j]
              this.spanArr[row * this.colFields.length + j] = {
                rowspan: 1 + beforeItem.rowspan, // 合并几行
                colspan: 1, // 合并几列,我这里只是跨行合并,不跨列合并,所以用的1
              }
              beforeItem.rowspan = 0
              beforeItem.colspan = 0
            } else {
              // rowspan 和 colspan 都为1表格此单元格不合并
              this.spanArr[row * this.colFields.length + j] = {
                rowspan: 1,
                colspan: 1,
              }
            }
          }
        }
      }
      // 对数据进行倒序
      let stack = []
      for (let i = 0; i < this.colFields.length; i++) {
        for (let j = 0; j < this.taxTypesTableData.length; j++) {
          // console.log("i=" + i + " j=" + j);
          // i 表示列 j表示行
          if (j === 0) {
            if (this.spanArr[j * this.colFields.length + i].rowspan === 0) {
              stack.push(this.spanArr[j * this.colFields.length + i])
            }
          } else {
            if (this.spanArr[j * this.colFields.length + i].rowspan === 0) {
              stack.push(this.spanArr[j * this.colFields.length + i])
            } else {
              stack.push(this.spanArr[j * this.colFields.length + i])
              while (stack.length > 0) {
                let pop = stack.pop()
                let len = stack.length
                this.spanArr[(j - len) * this.colFields.length + i] = pop
              }
            }
          }
        }
      }
    },
    texTableCellMerge({ row, column, rowIndex, columnIndex }) {
      return this.spanArr[rowIndex * this.colFields.length + columnIndex]
    },
    // 获取外部协作费用
    calculateCooperationMoney(ourContractAmount) {
      if (this.form.contentCooperationType === 1) {
        return Number(delcommafy(this.form.contentCooperationMoney))
      } else if (this.form.contentCooperationType === 2) {
        return ourContractAmount * (Number(this.form.contentCooperationRatio) / 100)
      }
      return 0
    },
    // 解决浮点数，计算增值税公式
    calculateVat(ourContractAmount) {
      return (
        (((ourContractAmount * 10000) / (1000000 + this.form.vatRate * 10000)) *
          (this.form.vatRate * 10000)) /
        10000
      ).toFixed(2)
    },
    onChangeTaxtype(val) {
      if (val === 'THIRD_PARTY_TAX') {
        this.form.vatType = null
        this.form.vatData = null
        this.form.vatRate = null
      } else if (val === 'SPLIT_TAX') {
        // if (this.form.externalPartnere != 'HAVE') {
        //   this.$confirm('此商务是否有外部合作者', '提示', {
        //     confirmButtonText: '确定',
        //     cancelButtonText: '取消',
        //     type: 'warning',
        //   })
        //     .then(() => {
        //       this.form.externalPartnere = 'HAVE'
        //     })
        //     .catch(() => {
        //       this.form.taxType = null
        //     })
        // }
      }
    },
    mouseoverBusinessName() {
      if (this.form.businessName && this.form.businessName.length > 50) {
        this.isBusinessNameTooltip = true
      } else {
        this.isBusinessNameTooltip = false
      }
    },
    mouseoutBusinessName() {
      this.isBusinessNameTooltip = false
    },
    listConfig() {
      this.$api.vat
        .getVatTypeList()
        .then(res => {
          this.zzsList = res?.data?.records
        })
        .catch(err => {
          console.log(err)
        })
    },
    externalPartnereFn(val) {
      if (val == 'NOT') {
        this.form.contentCooperationType = null
        this.form.contentCooperationMoney = null
        this.form.contentCooperationRatio = null
        this.form.contentCooperationContent = null
        // this.$refs.form.clearValidate('content')
        if (this.form.taxType == 'SPLIT_TAX') {
          this.form.taxType = null
        }
      }
    },
    vatTypeTableCellStyle({ row, columnIndex }) {
      let styleArr = ['cellName']
      if (row.dictDataThreeList) {
        styleArr.push('cellContent')
        if (row.hasChildren && columnIndex == 1) {
          styleArr.push('clearBorder')
        }
        return styleArr.join(' ')
      } else {
        styleArr.push('cellTitle')
        if (columnIndex == 0) {
          styleArr.push('cellTitleFont')
        } else if (columnIndex == 2) {
          styleArr.push('cellTitleAlign')
        }
        return styleArr.join(' ')
      }
    },

    // 显示增值税弹框
    showVatTypesShow() {
      this.$api.vat
        .getTreeDataList()
        .then(res => {
          this.VatTypesTableData = res.data
          this.VatTypesTableData.forEach((item, index) => {
            item.id = index + ''
            if (item.dictDataTwoList) {
              item.dictDataTwoList.forEach((item2, index2) => {
                if (item2.dictDataThreeList && item2.dictDataThreeList.length !== 0) {
                  item2.hasChildren = true
                }
                item2.id = String(index) + String(index2)
              })
            }
          })
          this.vatTypesShow = true
        })
        .catch(err => {
          console.log(err)
        })
    },

    // 显示业务类型弹框
    showBusinessLinesIsShow() {
      this.$api.dict
        .getTreeDataList({ dictType: 'BUSINESS_LINES' })
        .then(res => {
          this.businessLinesTableData = res.data
          this.businessLinesIsShow = true
        })
        .catch(err => {
          console.log(err)
        })
    },
    clearColumnPadding({ columnIndex }) {
      if (columnIndex == 1) {
        return 'cellName clearBorder'
      }
    },
    arraySpanMethod({ row, columnIndex }) {
      if (row.hasChildren) {
        if (columnIndex == 1) {
          return [1, 2]
        }
      }
    },
    optionsChange(v) {
      if (v[0]) {
        const vatRate = v.find(item => item.vatType == this.temporaryVatRate)
        if (vatRate) {
          this.form.vatRate = this.temporaryVatRate
        } else {
          this.form.vatRate = v[0].vatType
        }
      }
    },
    fileListFn(file) {
      this.businessFileList = file
    },
    delFn() {
      this.$confirm('此操作将删除该商务信息, 是否继续?', '提示', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning',
      })
        .then(() => {
          this.$api.businessManage
            .deleteBusiness(this.options.id)
            .then(res => {
              this.$message({
                type: 'success',
                message: '删除成功!',
              })
              this.showEditDialog = false
              this.re()
            })
            .catch(err => {
              console.log(err)
            })
        })
        .catch(() => {
          this.$message({
            type: 'info',
            message: '已取消删除',
          })
        })
    },
    /** 值改变触发 "申请金额" 文本框 **/
    inputFn(e) {
      this.form[e] = String(this.form[e])
        .replace(/[^\d.]/g, '')
        .replace(/^(\-)*(\d+)\.(\d\d).*$/, '$1$2.$3')
      this.$forceUpdate()
    },

    /** 获得焦点触发 "申请金额" 文本框 **/
    focusFn(e) {
      this.form[e] = this.form[e] ? this.form[e] : 0
      this.form[e] = Number(String(this.form[e]).replace(/,/g, ''))
      this.$forceUpdate()
    },

    /** 失去焦点触发 "申请金额" 文本框 **/
    blurFn(e) {
      if (this.form.ourContractAmount > 0 && this.form.ourContractAmount <= 5000) {
        this.isourContractAmount = true
      } else {
        this.isourContractAmount = false
      }
      this.form[e] = Number(this.form[e]).toLocaleString()
      this.$forceUpdate()
    },
    init() {
      this.loading = true
      this.$api.user.staffAllList({}).then(res => {
        this.user_Data = res.data
        this.$store.commit({
          type: 'business/SET_COMPANY_MEBERS',
          CompanyMembers: res.data,
        })
        this.getData()
      })
    },
    dataUpdate(row) {
      if (row) {
        this.form.vatData = null
        this.form.vatRate = null
      }
    },
    inputMoney(el, name) {
      if (this.form.ourContractAmount > 0 && this.form.ourContractAmount <= 5000) {
        this.isourContractAmount = true
      } else {
        this.isourContractAmount = false
      }
      this.form[name] = getInputValue(el)
    },
    handleSave(status) {
      if (this.isourContractAmount) return
      if (
        this.businessFileList.some(r => {
          return r.loading
        })
      ) {
        this.$message.warning('文件上传中，请稍后再试！')
        return ''
      }

      this.$refs.form.validate((valid, obj) => {
        if (valid) {
          this.form.status = status
          this.form.taskDate = new Date().getTime()
          this.form.ourContractAmount = Number.parseInt(
            //去除千分位
            delcommafy(this.form.ourContractAmount)
          )
          this.form.predictMoney = Number.parseInt(
            //去除千分位
            delcommafy(this.form.predictMoney)
          )
          this.form.contentCooperationMoney = Number.parseInt(
            delcommafy(this.form.contentCooperationMoney)
          )
          // this.form.collaborationCosts = Number.parseInt(delcommafy(this.form.collaborationCosts))
          this.form.tenderFee = Number.parseInt(delcommafy(this.form.tenderFee))
          if (this.form.contentCooperationType == 1) {
            this.form.contentCooperationRatio = 0
          } else if (this.form.contentCooperationType == 2) {
            this.form.contentCooperationMoney = 0
          }
          var cond = {
            businessFileList: this.businessFileList.deepClone(),
            businessFileDeleteList: [],
            businessManage: this.form,
          }

          if (this.y_file_Data != []) {
            //判断被删除得文件
            this.y_file_Data.some(res => {
              if (
                !cond.businessFileList.some(r => {
                  return r.id == res.id
                })
              ) {
                cond.businessFileDeleteList.push(res.id)
              }
            })
          }

          this.loading = true
          this.$api.businessManage
            .addBusinessManage(cond)
            .then(res => {
              this.loading = false
              this.showEditDialog = false
              this.$message.success('操作成功！')
              this.re()
            })
            .catch(err => {
              this.loading = false
              console.log(err)
            })
        } else {
          this.activeName = '0'
          this.scrollView(obj)
        }
      })
    },
    getData() {
      // this.loading = true
      this.$api.businessManage
        .getBusinessManage(this.val.id)
        .then(res => {
          let arr = []
          if (res.data.businessFileList.length > 0) {
            for (let i = 0; i < res.data.businessFileList.length; i++) {
              const e = res.data.businessFileList[i]
              e.attachmentName = e.fileName
              e.attachmentPath = e.filePath
              arr.push(e)
            }
          }
          this.file_Data = arr
          this.y_file_Data = arr.deepClone()
          this.form = res.data.businessManage
          // 当前商务状态为不通过编辑时，从商务名称列表中删除当前商务
          this.selectBusinessName = this.form.businessName
          //根据id查信息提供人
          if (this.user_Data.length > 0) {
            for (let index = 0; index < this.user_Data.length; index++) {
              const e = this.user_Data[index]
              if (e.id == res.data.businessManage.provideId) {
                // this.linshi_initiator = e.userName;
                const newArr = e.departmentName.split(',')
                this.linshi_initiator = e.userName + ' - ' + newArr[0]
              }
              if (e.id == res.data.businessManage.suggestionPrincipalUserId) {
                const newArr = e.departmentName.split(',')
                this.form.suggestionPrincipalUserName = e.userName + ' - ' + newArr[0]
              }
            }
            this.$forceUpdate()
          }
          this.opinionRecordList = []
          this.opinionRecordList = res.data.opinionRecordList
          this.form.ourContractAmount = numberToCurrencyNo(this.form.ourContractAmount) //变千分位
          this.form.predictMoney = numberToCurrencyNo(this.form.predictMoney) //变千分位
          this.form.tenderFee = numberToCurrencyNo(this.form.tenderFee) //变千分位
          this.form.contentCooperationMoney = numberToCurrencyNo(this.form.contentCooperationMoney)
          // this.form.collaborationCosts = numberToCurrencyNo(this.form.collaborationCosts)
          this.form.list_s = this.list_s
          this.$refs?.form.clearValidate() //清除表单验证提示
          this.loading = false
          // this.showEditDialog = this.isShow
        })
        .catch(err => {
          this.loading = false
          // this.showEditDialog = this.isShow
          console.log(err)
        })
    },
    getPartnerCompany() {
      this.$api.partnerCompany
        .getPartnerCompanyDataListNoPage({})
        .then(res => {
          if (res) {
            this.companyList = res.data.map(item => {
              return {
                value: item.name,
                id: item.id,
                contacts: item.legalPerson,
                post: item.post,
                contactsPhone: item.phone,
              }
            })
          }
        })
        .catch(err => {
          console.log(err)
        })
    },
    getBusinessNameList() {
      this.$api.businessManage
        .getNameListNoPage({})
        .then(res => {
          if (res) {
            this.businessNameList = res.data.map(item => {
              return { value: item }
            })
          }
        })
        .catch(err => {
          console.log(err)
        })
    },
    initiatorFn(row) {
      const arr = row.deptNames.split(',')
      this.linshi_initiator = row.userName + ' - ' + arr[0]
      this.form.provideId = row?.id
      this.$forceUpdate()
    },
    suggestionPrincipalUserNameFn(row) {
      const arr = row.deptNames.split(',')
      this.form.suggestionPrincipalUserName = row.userName + ' - ' + arr[0]
      this.form.suggestionPrincipalUserId = row?.id
      this.$forceUpdate()
    },
    is_number(el) {
      this.form[el] = this.form[el].replace(/[^\d.]/g, '')
    },
    getLinshi_initiator(v) {
      let i = ''
      this.user_Data.forEach(e => {
        if (e.id == v) {
          var arr = e.departmentName.split(',')
          i = e.userName + ' - ' + arr[0]
        }
      })
      return i
    },

    // 可筛选输入框回调
    querySearch(queryString, cb) {
      let companyList = this.companyList
      let results = queryString ? this.createFilter(queryString) : companyList
      // 调用 callback 返回建议列表的数据
      cb(results)
    },

    createFilter(queryString) {
      let results = this.companyList
      // 将输入的内容进行拆分，返回值为一个数组
      const queryStringList = queryString.split('')
      if (queryStringList.length > 0) {
        queryStringList.forEach(queryItem => {
          let filterResults = results.filter(companyItem => companyItem.value?.includes(queryItem))
          results = [...filterResults]
        })
      }
      return results
    },

    onInputSelect(e) {
      if (e.value) {
        this.form.ownerUnit = e.value
        this.form.contactsPhone = e.contactsPhone
        this.form.contacts = e.contacts
        this.form.post = e.post
        const arr = ['ownerUnit', 'contactsPhone', 'contacts', 'post']
        arr.forEach(ref => {
          if (this.$refs[ref]) {
            this.$refs[ref].clearValidate()
          }
        })
      }
      this.$forceUpdate()
    },

    businessQuerySearch(queryString, cb) {
      let businessNameList = this.businessNameList
      let results = queryString ? this.businessNameCreateFilter(queryString) : businessNameList
      // 调用 callback 返回建议列表的数据
      cb(results)
    },

    businessNameCreateFilter(queryString) {
      let results = this.businessNameList
      // 将输入的内容进行拆分，返回值为一个数组
      const queryStringList = queryString.split('')
      if (queryStringList.length > 0) {
        queryStringList.forEach(queryItem => {
          let filterResults = results.filter(nameItem => nameItem.value?.includes(queryItem))
          results = [...filterResults]
        })
      }
      return results
    },

    onBusinessNameSelect(e) {
      if (e) {
        this.form.businessName = e.value
        // this.$refs.businessName.clearValidate()
        this.$refs.form.validateField('businessName')
      }
      this.$forceUpdate()
    },
  },
}
</script>

<style scoped lang="scss">
// @import "@/styles/edit.scss";

// @import "@/styles/config.scss";

.el-form-item {
  margin-bottom: 5px;
  padding: 0;
}

.text-center {
  width: 100%;
  height: 300px;
  //   border: 1px solid #000;
  overflow: auto;
  padding: 16px 0;
  .text-center-top {
    margin-bottom: 10px;
    color: #000;
    background-color: #f3f3f5;
    display: flex;
    align-items: center;
    justify-content: space-between;

    .text-center-top-left {
      // width: 50%;
      flex: 1;
      display: flex;
      align-items: center;
      .text-btn {
        height: 48px;
        width: 90px;
        margin-right: 20px;
      }
      .opinion {
        display: inline-block;
        margin-left: 8px;
        width: 100%;
        word-break: break-word;
        padding: 5px 0;
        font-family: PingFang SC;
        font-weight: 600;
        // font-size: 18px;
      }
    }
    .text-center-top-right {
      width: 40%;
      display: flex;
      justify-content: flex-end;
      .day {
        margin-left: 10%;
        padding-right: 8px;
      }
    }
  }
}

.danwei {
  position: absolute;
  right: 2%;
}

.form_box {
  padding-top: 20px;
}

.margin-top {
  margin-top: 10px;
}
.big_box_div {
  width: 49%;
  float: left;
}
/deep/.el-loading-spinner {
  margin-top: 21%;
}
.radio-group {
  display: flex;
  align-items: center;
  height: 45px;
}
.radio-label {
  width: 100px;
  font-size: 14px;
}
/deep/.cellName {
  .cell {
    padding: 0;
  }
  padding: 0 !important;
}

.remark-box {
  padding-left: 10px;
}
/deep/.height-light {
  color: red;
  font-size: 20px;
}
.vatDialog {
  /deep/ .el-table th {
    border-color: #303133 !important;
  }
  /deep/ .el-table td {
    border-color: #303133 !important;
    height: 36px;
    line-height: 36px;
    color: #000;
  }
  /deep/ .el-table--group,
  /deep/ .el-table--border {
    border: 1px solid #303133 !important;
  }
  /deep/.cellContent {
    background-color: rgb(222, 235, 247) !important;
    text-align: center;
  }
  /deep/.cellTitle {
    background-color: rgb(189, 215, 238) !important;
  }
  /deep/.el-table tbody tr:hover > td {
    background-color: transparent;
  }
  /deep/.cellTitleFont {
    font-weight: 700;
  }
  /deep/.cellTitleAlign {
    text-align: center;
  }
  /deep/.clearBorder {
    border-right: none !important;
  }
}

.businessLinesDialog {
  /deep/ .el-table th {
    border-color: #303133 !important;
  }
  /deep/ .el-table td {
    border-color: #303133 !important;
    height: 32px;
    line-height: 32px;
  }
  /deep/ .el-table--group,
  /deep/ .el-table--border {
    border: 1px solid #303133 !important;
  }
  /deep/.clearBorder {
    border-right: none !important;
  }
  /deep/.content-text {
    color: #000;
  }
}
/deep/.el-descriptions__title {
  display: flex;
  justify-content: space-between;
  width: 100%;
  .title_right {
    color: red;
    font-weight: 900;
    font-size: 18px;
  }
}
.businessNameTooltip {
  white-space: nowrap;
  position: absolute;
  bottom: -30px;
  left: -30px;
  background-color: #000;
  color: #fff;
  z-index: 99999;
  line-height: 30px;
  border-radius: 5px;
  padding: 0 5px;
}
</style>
